<template>
  <v-navigation-drawer v-model="isOpenDrawer" class="white" app dark disable-resize-watcher>
    <v-list-item>
      <v-list-item-content>
        <v-img
          :src="require('../../assets/acasialogo-ungu.svg')"
          contain
          max-width="70px"
        />
      </v-list-item-content>
        <v-icon @click="closeDrawer()" class="right-close-navbar onHover">mdi-close-circle-outline</v-icon>
    </v-list-item>
    
    <div  class="">
    </div>

    <v-divider></v-divider>

    <v-list dense nav>

      <!-- <v-list-item active-class="activeHover" class="onHover" v-for="item in items" :key="item.title" :to="item.link" link> -->

      <v-list-item v-for="item in items" :disabled="item.title=='Promotion' && $store.state.auth.user_role == 'PASSENGER-BASIC'" :key="item.title"  active-class="activeHover" class="onHover"  :to="item.link" link>
        <v-list-item-icon>
          <!-- <icon class="user-element"></icon> -->
          <v-img
          class="title-navbar"
          :src="item.icon"
          contain
          max-width="70px"
        />
          <!-- <div>
          <svg ></svg></div> -->
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-if="item.title=='Membership' && $store.state.auth.user_role == 'PASSENGER-BASIC'" class="title-navbar">{{ item.title }} 
            <v-btn class="upgrade-member" readonly rounded small color="primary" v-if="item.title == 'Membership' && $store.state.auth.user_role == 'PASSENGER-BASIC'"> <p class="upgrade-member-label">{{$t("Upgrade")}}</p> </v-btn>
          </v-list-item-title>

          <v-list-item-title v-else class="title-navbar">{{ item.title }}
          </v-list-item-title>
          
        </v-list-item-content>
      </v-list-item>

      <!-- parental access -->
      <v-list-item v-if="$store.state.auth.user_role == 'PASSENGER-PARRENTS' || $store.state.auth.user_role == 'PASSENGER-PREMIUM'" active-class="activeHover" class="onHover" to="/parent/parentalcccess" >
        <v-list-item-icon>
          <v-icon 
      color="black">mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title-navbar">{{$t("ParentalAccess")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- parental access -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Beranda", icon: require('../../assets/navbar/home-icon.svg'), link: "/home" },
        { title: "Profile", icon: require('../../assets/navbar/profile.svg'), link: "/about" },
        { title: "Membership", icon: require('../../assets/navbar/crown.svg'), link: "/premiummember" },
        { title: "Promotion", icon: require('../../assets/navbar/bintang.svg'), link: "/promotionprogram" },
        { title: "Transaction History", icon: require('../../assets/navbar/history.svg'), link: "#" },
        { title: "Languange", icon: require('../../assets/navbar/globe.svg'), link: "/languange" },
      ],
      right: null,
      role: this.$store.state.auth.user_role,
      // parental_access: this.$store.state.auth.parentalAccess
    };
  },
  computed: {
    isOpenDrawer: {
      get() {
        return this.$store.state.moduleUi.toggleDrawer;
      },
      set(state) {
        this.$store.commit("moduleUi/TOGGLE_DRAWER", false);
        if (state) {
          this.$store.commit("moduleUi/TOGGLE_DRAWER", true);
        }
      },
    },
    // parental(){
    //   return this.$store.state.auth.parentalAccess;
    // },
  },
  methods: {
    closeDrawer() {
      this.$store.commit("moduleUi/TOGGLE_DRAWER", false);
    },
  },
  watch:{
    // role(){
    //   if(this.role == 'PASSENGER-PARRENTS'){
    //       this.items.push(
    //     { title: "Promotion", icon: require('../../assets/navbar/bintang.svg'), link: "/promotionprogram" })
    //   } 
    // }, 
    // parental(){
    //   if( this.parental == "parent"){
    //       this.items.push(
    //           { title: "Parental Access", icon: "mdi-account-tie", link: "/parent/parentalcccess" });
    //       }
    //   }, 
  },
};
</script>

<style lang="scss" scoped>
.onHover:hover{
  cursor: pointer;
  color: #4BB14E;
  background:lighten( #4BB14E,25%);
}

.activeHover {
  cursor: pointer;
  color: #4BB14E;
  background:lighten( #4BB14E,25%);
}

.right-close-navbar {
  color:#4BB14E;
  position: absolute;
  right: 0px;
}
.title-navbar{
  color: #252525;
}

.right-close-navbar {
  color:#4BB14E;
  position: absolute;
  right: 0px;
}
.user-element { 
  background-image: url('../../assets/navbar/globe.svg');
}
.upgrade-member{
  // button
  margin-left: 10%;
  width: 40%;

}
.upgrade-member-label{
  // textfont-style: normal;
  margin-top: 30%;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: #FFFFFF;

}
</style>